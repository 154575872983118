<template>
  <v-app-bar color="primary" height="60" elevate-on-scroll dark app>
    <v-img
      @click="home()"
      src="@/assets/gambar/logo.png"
      max-height="50"
      max-width="50"
      class="my-auto"
    ></v-img>
    <div class="ml-2">
      <h3>INSPEKTORAT</h3>
      <span>POHUWATO</span>
    </div>
    <v-spacer></v-spacer>
    <div class="hidden-md-and-down">
      <!-- PROFIL -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Profil
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/profil/tentang">
            <v-list-item-title>Tentang</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/visimisi">
            <v-list-item-title>Visi & Misi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/struktur">
            <v-list-item-title>Struktur Organisasi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/tupoksi">
            <v-list-item-title>Tupoksi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/pegawai">
            <v-list-item-title>Daftar Pegawai</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/strategi">
            <v-list-item-title>Strategi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profil/kebijakan">
            <v-list-item-title>Kebijakan</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- INFORMASI -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Informasi
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/informasi/berita">
            <v-list-item-title>Berita</v-list-item-title>
          </v-list-item>

          <v-list-item to="/informasi/pengumuman">
            <v-list-item-title>Pengumuman</v-list-item-title>
          </v-list-item>

          <v-list-item to="/informasi/produkhukum">
            <v-list-item-title>Produk Hukum</v-list-item-title>
          </v-list-item>

          <v-list-item to="/informasi/apip">
            <v-list-item-title>Kapabilitas APIP</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- GALERI -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Galeri
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/galeri/foto">
            <v-list-item-title>Foto</v-list-item-title>
          </v-list-item>

          <v-list-item to="/galeri/video">
            <v-list-item-title>Video</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- PROGRAM -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Program
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item>
            <v-list-item-title class="font-weight-black"
              >Kegiatan Inspektorat</v-list-item-title
            >
          </v-list-item>

          <v-list-item to="/program/kegiatan/2019">
            <v-list-item-title>Tahun 2019</v-list-item-title>
          </v-list-item>

          <v-list-item to="/program/kegiatan/2020">
            <v-list-item-title>Tahun 2020</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item to="/program/laporan">
            <v-list-item-title>Laporan Inspektorat</v-list-item-title>
          </v-list-item>

          <v-list-item to="/program/pmprb">
            <v-list-item-title>PMPRB</v-list-item-title>
          </v-list-item>

          <v-list-item to="/program/spip">
            <v-list-item-title>SPIP</v-list-item-title>
          </v-list-item>

          <v-list-item to="/program/zointeg">
            <v-list-item-title>Zona Integritas</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- GRATIFIKASI -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Gratifikasi
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/gratifikasi/pengertian">
            <v-list-item-title>Pengertian Gratifikasi</v-list-item-title>
          </v-list-item>

          <v-list-item to="/gratifikasi/tatacara">
            <v-list-item-title>Tata Cara Pelaporan</v-list-item-title>
          </v-list-item>

          <v-list-item to="/gratifikasi/blanko">
            <v-list-item-title>Blanko Pelaporan</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- MCP KPK -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            MCP KPK
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/mcpkpk/infoumum">
            <v-list-item-title>Informasi Umum</v-list-item-title>
          </v-list-item>

          <v-list-item to="/mcpkpk/capaian">
            <v-list-item-title>Capaian</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- PENGADUAN -->
      <v-menu open-on-hover bottom offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            Pengaduan
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>

        <v-list dense class="px-2">
          <v-list-item to="/pengaduan/sop">
            <v-list-item-title>SOP Pengaduan</v-list-item-title>
          </v-list-item>

          <v-list-item to="/pengaduan/beranda">
            <v-list-item-title>Pengaduan</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text to="/kontak">
        Kontak
      </v-btn>
    </div>

    <!-- MOBILE -->
    <div class="hidden-lg-and-up">
      <v-btn icon @click="toggleDrawer()">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import drawerState from "@/store/drawerMobile";

export default {
  name: "Menu",

  data: () => ({
    session: "",
  }),

  created() {
    if (
      this.$vuetify.breakpoint.name == "lg" ||
      this.$vuetify.breakpoint.name == "xl"
    ) {
      drawerState.commit("toggle", false);
    }

    // console.log(this.$vuetify.breakpoint.name);
  },

  methods: {
    toggleDrawer() {
      drawerState.commit("toggle", !drawerState.state.drawer);
    },

    home() {
      this.$router.push("/home").catch(() => {});
    },
  },
};
</script>
