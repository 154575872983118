<template>
  <v-app id="inspire" style="background: #f5f6f8">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
