import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                CGray: '#EDF2F7',
                CBackground: '#f5f6f8'
            }
        }
    }
});
