var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","height":"60","elevate-on-scroll":"","dark":"","app":""}},[_c('v-img',{staticClass:"my-auto",attrs:{"src":require("@/assets/gambar/logo.png"),"max-height":"50","max-width":"50"},on:{"click":function($event){return _vm.home()}}}),_c('div',{staticClass:"ml-2"},[_c('h3',[_vm._v("INSPEKTORAT")]),_c('span',[_vm._v("POHUWATO")])]),_c('v-spacer'),_c('div',{staticClass:"hidden-md-and-down"},[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Profil "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/profil/tentang"}},[_c('v-list-item-title',[_vm._v("Tentang")])],1),_c('v-list-item',{attrs:{"to":"/profil/visimisi"}},[_c('v-list-item-title',[_vm._v("Visi & Misi")])],1),_c('v-list-item',{attrs:{"to":"/profil/struktur"}},[_c('v-list-item-title',[_vm._v("Struktur Organisasi")])],1),_c('v-list-item',{attrs:{"to":"/profil/tupoksi"}},[_c('v-list-item-title',[_vm._v("Tupoksi")])],1),_c('v-list-item',{attrs:{"to":"/profil/pegawai"}},[_c('v-list-item-title',[_vm._v("Daftar Pegawai")])],1),_c('v-list-item',{attrs:{"to":"/profil/strategi"}},[_c('v-list-item-title',[_vm._v("Strategi")])],1),_c('v-list-item',{attrs:{"to":"/profil/kebijakan"}},[_c('v-list-item-title',[_vm._v("Kebijakan")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Informasi "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/informasi/berita"}},[_c('v-list-item-title',[_vm._v("Berita")])],1),_c('v-list-item',{attrs:{"to":"/informasi/pengumuman"}},[_c('v-list-item-title',[_vm._v("Pengumuman")])],1),_c('v-list-item',{attrs:{"to":"/informasi/produkhukum"}},[_c('v-list-item-title',[_vm._v("Produk Hukum")])],1),_c('v-list-item',{attrs:{"to":"/informasi/apip"}},[_c('v-list-item-title',[_vm._v("Kapabilitas APIP")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Galeri "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/galeri/foto"}},[_c('v-list-item-title',[_vm._v("Foto")])],1),_c('v-list-item',{attrs:{"to":"/galeri/video"}},[_c('v-list-item-title',[_vm._v("Video")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Program "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',{staticClass:"font-weight-black"},[_vm._v("Kegiatan Inspektorat")])],1),_c('v-list-item',{attrs:{"to":"/program/kegiatan/2019"}},[_c('v-list-item-title',[_vm._v("Tahun 2019")])],1),_c('v-list-item',{attrs:{"to":"/program/kegiatan/2020"}},[_c('v-list-item-title',[_vm._v("Tahun 2020")])],1),_c('v-divider'),_c('v-list-item',{attrs:{"to":"/program/laporan"}},[_c('v-list-item-title',[_vm._v("Laporan Inspektorat")])],1),_c('v-list-item',{attrs:{"to":"/program/pmprb"}},[_c('v-list-item-title',[_vm._v("PMPRB")])],1),_c('v-list-item',{attrs:{"to":"/program/spip"}},[_c('v-list-item-title',[_vm._v("SPIP")])],1),_c('v-list-item',{attrs:{"to":"/program/zointeg"}},[_c('v-list-item-title',[_vm._v("Zona Integritas")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Gratifikasi "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/gratifikasi/pengertian"}},[_c('v-list-item-title',[_vm._v("Pengertian Gratifikasi")])],1),_c('v-list-item',{attrs:{"to":"/gratifikasi/tatacara"}},[_c('v-list-item-title',[_vm._v("Tata Cara Pelaporan")])],1),_c('v-list-item',{attrs:{"to":"/gratifikasi/blanko"}},[_c('v-list-item-title',[_vm._v("Blanko Pelaporan")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" MCP KPK "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/mcpkpk/infoumum"}},[_c('v-list-item-title',[_vm._v("Informasi Umum")])],1),_c('v-list-item',{attrs:{"to":"/mcpkpk/capaian"}},[_c('v-list-item-title',[_vm._v("Capaian")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Pengaduan "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',{staticClass:"px-2",attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/pengaduan/sop"}},[_c('v-list-item-title',[_vm._v("SOP Pengaduan")])],1),_c('v-list-item',{attrs:{"to":"/pengaduan/beranda"}},[_c('v-list-item-title',[_vm._v("Pengaduan")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","to":"/kontak"}},[_vm._v(" Kontak ")])],1),_c('div',{staticClass:"hidden-lg-and-up"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleDrawer()}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }