<template>
  <div>
    <CHeader />

    <CMenu />

    <v-main class="my-3">
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import CHeader from "@/layout/Admin/Header";
import CMenu from "@/layout/Admin/Menu";

export default {
  components: {
    CHeader,
    CMenu,
  },

  data: () => ({}),

  mounted() {
    if (this.$route.path === "/admin" || this.$route.path === "/admin/") {
      this.$router.push("/admin/home");
    }
  },
};
</script>
