import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from '@/helper/cookie.js'

import Main from '../layout/Frontend/Main.vue'
import AdminMain from '../layout/Admin/Main.vue'

Vue.use(VueRouter)

const routes = [

  {
    // catch all 404 - define at the very end
    path: "*",
    name: 'notfound',
    component: () => import( /* webpackChunkName: "notfound" */ '../layout/notfound.vue')
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(
      /* webpackChunkName: "Login" */
      /* webpackPrefetch: true */
      /* webpackPreload: true */
      '../views/Frontend/Login.vue'
    )
  },

  // FRONTEND
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [{
        path: '/home',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (home.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "home" */ '../views/Frontend/Home.vue')
      },
      {
        path: '/profil/tentang',
        name: 'tentangProfil',
        component: () => import( /* webpackChunkName: "tentangProfil" */ '../views/Frontend/profil/Tentang.vue')
      },
      {
        path: '/profil/visimisi',
        name: 'visimisiProfil',
        component: () => import( /* webpackChunkName: "visimisiProfil" */ '../views/Frontend/profil/Visimisi.vue')
      },
      {
        path: '/profil/struktur',
        name: 'strukturProfil',
        component: () => import( /* webpackChunkName: "strukturProfil" */ '../views/Frontend/profil/Struktur.vue')
      },
      {
        path: '/profil/pegawai',
        name: 'pegawaiProfil',
        component: () => import( /* webpackChunkName: "pegawaiProfil" */ '../views/Frontend/profil/Pegawai.vue')
      },
      {
        path: '/profil/tupoksi',
        name: 'tupoksiProfil',
        component: () => import( /* webpackChunkName: "tupoksiProfil" */ '../views/Frontend/profil/Tupoksi.vue')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import( /* webpackChunkName: "about" */ '../views/Frontend/About.vue')
      },

      {
        path: '/pengaduan/beranda',
        name: 'Pengaduan',
        component: () => import( /* webpackChunkName: "pengaduan" */ '../views/Frontend/pengaduan/Pengaduan.vue')
      },
      {
        path: '/pengaduan/detail/:id',
        name: 'PengaduanDetail',
        component: () => import( /* webpackChunkName: "pengaduanDetail" */ '../views/Frontend/pengaduan/PengaduanDetail.vue')
      },
      {
        path: '/pengaduan/form',
        name: 'PengaduanForm',
        component: () => import( /* webpackChunkName: "pengaduanForm" */ '../components/Frontend/pengaduan/form.vue')
      },
      {
        path: '/pengaduan/sop',
        name: 'PengaduanSOP',
        component: () => import( /* webpackChunkName: "pengaduanSOP" */ '../views/Frontend/pengaduan/Sop.vue')
      },

      {
        path: '/galeri/foto',
        name: 'galeriFoto',
        component: () => import( /* webpackChunkName: "galeriFoto" */ '../views/Frontend/galeri/Foto.vue')
      },
      {
        path: '/galeri/video',
        name: 'galeriVideo',
        component: () => import( /* webpackChunkName: "galeriVideo" */ '../views/Frontend/galeri/Video.vue')
      },

      {
        path: '/kontak',
        name: 'Kontak',
        component: () => import( /* webpackChunkName: "kontak" */ '../views/Frontend/Kontak.vue')
      },

      {
        path: '/informasi/berita',
        name: 'Berita',
        component: () => import( /* webpackChunkName: "berita" */ '../views/Frontend/informasi/Berita.vue')
      },
      {
        path: '/informasi/pengumuman',
        name: 'Pengumuman',
        component: () => import( /* webpackChunkName: "pengumuman" */ '../views/Frontend/informasi/Pengumuman.vue')
      },
      {
        path: '/informasi/berita/:id',
        name: 'readmoreBerita',
        component: () => import( /* webpackChunkName: "readmoreberita" */ '../components/Frontend/informasi/berita/readmore.vue')
      },
      {
        path: '/informasi/produkhukum',
        name: 'Produkhukum',
        component: () => import( /* webpackChunkName: "Produkhukum" */ '../views/Frontend/informasi/Produkhukum.vue')
      },
      {
        path: '/informasi/apip',
        name: 'Apip',
        component: () => import( /* webpackChunkName: "Apip" */ '../views/Frontend/informasi/Apip.vue')
      },

      {
        path: '/mcpkpk/infoumum',
        name: 'Infoumum',
        component: () => import( /* webpackChunkName: "Infoumum" */ '../views/Frontend/mcpkpk/Infoumum.vue')
      },
      {
        path: '/mcpkpk/capaian',
        name: 'Capaian',
        component: () => import( /* webpackChunkName: "capaian" */ '../views/Frontend/mcpkpk/Capaian.vue')
      },

      {
        path: '/gratifikasi/pengertian',
        name: 'pengertianGratif',
        component: () => import( /* webpackChunkName: "pengertianGratif" */ '../views/Frontend/gratifikasi/Pengertian.vue')
      },
      {
        path: '/gratifikasi/tatacara',
        name: 'tatacaraGratif',
        component: () => import( /* webpackChunkName: "tatacaraGratif" */ '../views/Frontend/gratifikasi/Tatacara.vue')
      },
      {
        path: '/gratifikasi/blanko',
        name: 'Blanko',
        component: () => import( /* webpackChunkName: "blanko" */ '../views/Frontend/gratifikasi/Blanko.vue')
      },

      {
        path: '/program/pmprb',
        name: 'Pmprb',
        component: () => import( /* webpackChunkName: "pmprb" */ '../views/Frontend/program/PMPRB.vue')
      },
      {
        path: '/program/spip',
        name: 'Spip',
        component: () => import( /* webpackChunkName: "spip" */ '../views/Frontend/program/SPIP.vue')
      },
      {
        path: '/program/zointeg',
        name: 'Zointeg',
        component: () => import( /* webpackChunkName: "zointeg" */ '../views/Frontend/program/Zointeg.vue')
      },
    ]
  },

  // ADMIN
  {
    path: '/admin',
    name: 'AdminMain',
    component: AdminMain,
    meta: {
      requiresAuth: true
    },
    children: [{
        path: '/admin/home',
        name: 'HomeAdmin',
        // route level code-splitting
        // this generates a separate chunk (home.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import( /* webpackChunkName: "homeAdmin" */ '../views/Admin/Home.vue')
      },
      {
        path: '/admin/user',
        name: 'UserAdmin',
        component: () => import( /* webpackChunkName: "userAdmin" */ '../views/Admin/User.vue')
      },

      {
        path: '/admin/profil/tentang',
        name: 'TentangAdmin',
        component: () => import( /* webpackChunkName: "tentangAdmin" */ '../views/Admin/profil/Tentang.vue')
      },
      {
        path: '/admin/profil/visimisi',
        name: 'VisimisiAdmin',
        component: () => import( /* webpackChunkName: "visimisiAdmin" */ '../views/Admin/profil/Visimisi.vue')
      },
      {
        path: '/admin/profil/struktur',
        name: 'StrukturAdmin',
        component: () => import( /* webpackChunkName: "strukturAdmin" */ '../views/Admin/profil/Struktur.vue')
      },
      {
        path: '/admin/profil/pegawai',
        name: 'PegawaiAdmin',
        component: () => import( /* webpackChunkName: "pegawaiProfil" */ '../views/Admin/profil/Pegawai.vue')
      },
      {
        path: '/admin/profil/tupoksi',
        name: 'TupoksiAdmin',
        component: () => import( /* webpackChunkName: "tupoksiAdmin" */ '../views/Admin/profil/Tupoksi.vue')
      },

      {
        path: '/admin/informasi/berita',
        name: 'BeritaAdmin',
        component: () => import( /* webpackChunkName: "beritaAdmin" */ '../views/Admin/informasi/Berita.vue')
      },
      {
        path: '/admin/informasi/pengumuman',
        name: 'PengumumanAdmin',
        component: () => import( /* webpackChunkName: "pengumumanAdmin" */ '../views/Admin/informasi/Pengumuman.vue')
      },
      {
        path: '/admin/informasi/produkhukum',
        name: 'ProdukHukumAdmin',
        component: () => import( /* webpackChunkName: "produkhukumAdmin" */ '../views/Admin/informasi/Produkhukum.vue')
      },
      {
        path: '/admin/informasi/apip',
        name: 'ApipAdmin',
        component: () => import( /* webpackChunkName: "apipAdmin" */ '../views/Admin/informasi/Apip.vue')
      },

      {
        path: '/admin/pengaduan/data',
        name: 'PengaduanAdmin',
        component: () => import( /* webpackChunkName: "pengaduanAdmin" */ '../views/Admin/pengaduan/Pengaduan.vue')
      },
      {
        path: '/admin/pengaduan/selesai',
        name: 'PengaduanSelesaiAdmin',
        component: () => import( /* webpackChunkName: "pengaduanSelesaiAdmin" */ '../views/Admin/pengaduan/PengaduanSelesai.vue')
      },
      {
        path: '/admin/pengaduan/sop',
        name: 'SopPengaduanAdmin',
        component: () => import( /* webpackChunkName: "soppengaduanAdmin" */ '../views/Admin/pengaduan/Sop.vue')
      },

      {
        path: '/admin/galeri/foto',
        name: 'fotoAdmin',
        component: () => import( /* webpackChunkName: "fotoAdmin" */ '../views/Admin/galeri/Foto.vue')
      },
      {
        path: '/admin/galeri/video',
        name: 'videoAdmin',
        component: () => import( /* webpackChunkName: "videoAdmin" */ '../views/Admin/galeri/Video.vue')
      },

      {
        path: '/admin/mcpkpk/informasiumum',
        name: 'mcpkpkinfoAdmin',
        component: () => import( /* webpackChunkName: "mcpkpkinfoAdmin" */ '../views/Admin/mcpkpk/Infoumum.vue')
      },
      {
        path: '/admin/mcpkpk/capaian',
        name: 'mcpkpkcapaianAdmin',
        component: () => import( /* webpackChunkName: "mcpkpkcapaianAdmin" */ '../views/Admin/mcpkpk/Capaian.vue')
      },
      
      {
        path: '/admin/gratifikasi/pengertian',
        name: 'gpAdmin',
        component: () => import( /* webpackChunkName: "gpAdmin" */ '../views/Admin/gratifikasi/Pengertian.vue')
      },
      {
        path: '/admin/gratifikasi/tatacara',
        name: 'gtAdmin',
        component: () => import( /* webpackChunkName: "gtAdmin" */ '../views/Admin/gratifikasi/Tatacara.vue')
      },

      {
        path: '/admin/program/pmprb',
        name: 'pmprbAdmin',
        component: () => import( /* webpackChunkName: "pmprbAdmin" */ '../views/Admin/program/PMPRB.vue')
      },
      {
        path: '/admin/program/spip',
        name: 'spipAdmin',
        component: () => import( /* webpackChunkName: "spipAdmin" */ '../views/Admin/program/SPIP.vue')
      },
      {
        path: '/admin/program/zointeg',
        name: 'zointegAdmin',
        component: () => import( /* webpackChunkName: "zointegAdmin" */ '../views/Admin/program/Zointeg.vue')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  var myCookie = Cookie.get('myCookie')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!myCookie) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      })
    } else {
      const session = JSON.parse(Cookie.dec(Cookie.get('myCookie')))
      if (to.matched.some(record => record.meta.superadmin)) {
        if (session.user_lvl === 1) {
          next()
        } else {
          next({
            name: 'Home'
          })
        }
      } else if (to.matched.some(record => record.meta.admin)) {
        if (session.user_lvl !== 2) {
          next()
        } else {
          next({
            name: 'Home'
          })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router