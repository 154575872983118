<template>
  <v-navigation-drawer v-model="drawer" temporary right app>
    <v-list nav dense>
      <!-- PROFIL -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Profil</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/tentang"
        >
          <v-list-item-title>Tentang</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/visimisi"
        >
          <v-list-item-title>Visi & Misi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/struktur"
        >
          <v-list-item-title>Struktur Organisasi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/tupoksi"
        >
          <v-list-item-title>Tupoksi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/pegawai"
        >
          <v-list-item-title>Daftar Pegawai</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/strategi"
        >
          <v-list-item-title>Strategi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/profil/kebijakan"
        >
          <v-list-item-title>Kebijakan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- INFORMASI -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Informasi</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/informasi/berita"
        >
          <v-list-item-title>Berita</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/informasi/pengumuman"
        >
          <v-list-item-title>Pengumuman</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/informasi/produkhukum"
        >
          <v-list-item-title>Produk Hukum</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/informasi/apip"
        >
          <v-list-item-title>Kapabilitas APIP</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- GALERI -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Galeri</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/galeri/foto"
        >
          <v-list-item-title>Foto</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/galeri/video"
        >
          <v-list-item-title>Video</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- PROGRAM -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Program</v-list-item-title
          >
        </template>

        <v-list-group no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black"
                >Kegiatan Inspektorat</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item
            link
            active-class="primary--text text--accent-4"
            to="/program/kegiatan/2019"
          >
            <v-list-item-title>Tahun 2019</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            active-class="primary--text text--accent-4"
            to="/program/kegiatan/2020"
          >
            <v-list-item-title>Tahun 2020</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/program/laporan"
        >
          <v-list-item-title>Laporan Inspektorat</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/program/pmprb"
        >
          <v-list-item-title>PMPRB</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/program/spip"
        >
          <v-list-item-title>SPIP</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/program/zointeg"
        >
          <v-list-item-title>Zona Integritas</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- GRATIFIKASI -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Gratifikasi</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/gratifikasi/pengertian"
        >
          <v-list-item-title>Pengertian Gratifikasi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/gratifikasi/tatacara"
        >
          <v-list-item-title>Tata Cara Pelaporan</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/gratifikasi/blanko"
        >
          <v-list-item-title>Blanko Pelaporan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- MCP KPK -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >MCP KPK</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/mcpkpk/infoumum"
        >
          <v-list-item-title>Informasi Umum</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/mcpkpk/capaian"
        >
          <v-list-item-title>Capaian</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- PENGADUAN -->
      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-title class="font-weight-black"
            >Pengaduan</v-list-item-title
          >
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/pengaduan/sop"
        >
          <v-list-item-title>SOP Pengaduan</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/pengaduan/beranda"
        >
          <v-list-item-title>Pengaduan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <v-list-item
        link
        active-class="primary--text text--accent-4"
        to="/kontak"
      >
        <v-list-item-title class="font-weight-black">Kontak</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import drawerState from "@/store/drawerMobile";
export default {
  computed: {
    drawer: {
      get() {
        return drawerState.state.drawer;
      },

      set(value) {
        console.log("is drawer open: " + value);
        drawerState.commit("toggle", value);
      },
    },
  },

  data: () => ({
    session: "",
  }),
};
</script>
