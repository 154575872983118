<template>
  <v-navigation-drawer v-model="drawer" app>
    <v-list-item class="py-1">
      <v-list-item-avatar>
        <v-img src="@/assets/gambar/logo.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h6"> SI-Inspektorat </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        link
        active-class="primary--text text--accent-4"
        to="/admin/home"
      >
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- PROFIL -->
      <v-list-group prepend-icon="mdi-folder-home" no-action>
        <template v-slot:activator>
          <v-list-item-title>Profil</v-list-item-title>
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/tentang"
        >
          <v-list-item-title>Tentang</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/visimisi"
        >
          <v-list-item-title>Visi & Misi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/struktur"
        >
          <v-list-item-title>Struktur Organisasi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/tupoksi"
        >
          <v-list-item-title>Tupoksi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/pegawai"
        >
          <v-list-item-title>Daftar Pegawai</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/strategi"
        >
          <v-list-item-title>Strategi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/profil/kebijakan"
        >
          <v-list-item-title>Kebijakan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- INFORMASI -->
      <v-list-group prepend-icon="mdi-folder-information" no-action>
        <template v-slot:activator>
          <v-list-item-title>Informasi</v-list-item-title>
        </template>

        <!-- <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/informasi/visimisi"
        >
          <v-list-item-title>Visi & Misi Bupati Pohuwato</v-list-item-title>
        </v-list-item> -->

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/informasi/berita"
        >
          <v-list-item-title>Berita</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/informasi/pengumuman"
        >
          <v-list-item-title>Pengumuman</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/informasi/produkhukum"
        >
          <v-list-item-title>Produk Hukum</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/informasi/apip"
        >
          <v-list-item-title>Kapabilitas APIP</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- GALERI -->
      <v-list-group prepend-icon="mdi-folder-image" no-action>
        <template v-slot:activator>
          <v-list-item-title>Galeri</v-list-item-title>
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/galeri/foto"
        >
          <v-list-item-title>Foto</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/galeri/video"
        >
          <v-list-item-title>Video</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- PROGRAM -->
      <v-list-group prepend-icon="mdi-folder-text" no-action>
        <template v-slot:activator>
          <v-list-item-title>Program</v-list-item-title>
        </template>

        <v-list-group no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="font-weight-black"
                >Kegiatan Inspektorat</v-list-item-title
              >
            </v-list-item-content>
          </template>

          <v-list-item
            link
            active-class="primary--text text--accent-4"
            to="/admin/program/kegiatan/2019"
          >
            <v-list-item-title>Tahun 2019</v-list-item-title>
          </v-list-item>

          <v-list-item
            link
            active-class="primary--text text--accent-4"
            to="/admin/program/kegiatan/2020"
          >
            <v-list-item-title>Tahun 2020</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/program/laporan"
        >
          <v-list-item-title>Laporan Inspektorat</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/program/pmprb"
        >
          <v-list-item-title>PMPRB</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/program/spip"
        >
          <v-list-item-title>SPIP</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/program/zointeg"
        >
          <v-list-item-title>Zona Integritas</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- GRATIFIKASI -->
      <v-list-group prepend-icon="mdi-folder-zip" no-action>
        <template v-slot:activator>
          <v-list-item-title>Gratifikasi</v-list-item-title>
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/gratifikasi/pengertian"
        >
          <v-list-item-title>Pengertian Gratifikasi</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/gratifikasi/tatacara"
        >
          <v-list-item-title>Tata Cara Pelaporan</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/gratifikasi/blanko"
        >
          <v-list-item-title>Blanko Pelaporan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- MCP KPK -->
      <v-list-group prepend-icon="mdi-folder-table" no-action>
        <template v-slot:activator>
          <v-list-item-title>MCP KPK</v-list-item-title>
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/mcpkpk/informasiumum"
        >
          <v-list-item-title>Informasi Umum</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/mcpkpk/capaian"
        >
          <v-list-item-title>Capaian</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-divider></v-divider>

      <!-- PENGADUAN -->
      <v-list-group prepend-icon="mdi-folder-swap" no-action>
        <template v-slot:activator>
          <v-list-item-title>Pengaduan</v-list-item-title>
        </template>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/pengaduan/sop"
        >
          <v-list-item-title>SOP Pengaduan</v-list-item-title>
        </v-list-item>

        <v-list-item
          link
          active-class="primary--text text--accent-4"
          to="/admin/pengaduan/data"
        >
          <v-list-item-title>Pengaduan</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <!-- <v-divider></v-divider>

      <v-list-item
        link
        active-class="primary--text text--accent-4"
        to="/admin/kontak"
      >
        <v-list-item-icon>
          <v-icon>mdi-card-account-mail</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Kontak</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-divider></v-divider>

      <v-list-item
        link
        active-class="primary--text text--accent-4"
        to="/admin/user"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>User</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block small disabled> Versi : {{ appVersion }} </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from "../../../package";
// import Cookie from "@/helper/cookie.js";

import drawerState from "@/store/drawerAdmin";

export default {
  computed: {
    drawer: {
      get() {
        return drawerState.state.drawer;
      },
      set(value) {
        drawerState.commit("toggle", value);
      },
    },
  },

  data: () => ({
    appVersion: version,
    session: "",
  }),

  async mounted() {
    // this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },
};
</script>
