<template>
  <div>
    <Menu />
    <MobileMenu />
    <v-main style="background: #f5f6f8">
      <div class="my-3 mx-7">
        <router-view />
      </div>
    </v-main>
  </div>
</template>

<script>
import Menu from "@/layout/Frontend/Menu";
import MobileMenu from "@/layout/Frontend/MobileMenu";

export default {
  components: {
    Menu,
    MobileMenu,
  },

  created() {
    if (this.$route.path === "/") {
      this.$router.push("/home");
    }
  },
};
</script>
