<template>
  <v-app-bar dark app color="primary">
    <v-app-bar-nav-icon @click="toggleDrawer()"></v-app-bar-nav-icon>

    <v-toolbar-title>SISTEM INFORMASI INSPEKTORAT</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon x-large v-on="on">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list-item-content class="justify-center">
          <div class="mx-auto text-center">
            <div>
              <v-avatar size="100">
                  <v-img :src="getIMG(session.user_foto)" class="mx-auto"></v-img>
                </v-avatar>
            </div>
            <div class="my-3">
              <h3>{{ session.user_fullname }}</h3>
              <span class="subtitle-2">{{ session.user_nip }}</span>
            </div>
            <v-divider></v-divider>
            <v-btn depressed text block>
              Profil
            </v-btn>
            <v-divider></v-divider>
            <v-btn depressed text block @click="logOut()">
              Sigin Out
            </v-btn>
          </div>
        </v-list-item-content>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import drawerState from "@/store/drawerAdmin";

export default {
  data: () => ({
    session: "",
    drawer: null,
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
  },

  methods: {
    toggleDrawer() {
      drawerState.commit("toggle", !drawerState.state.drawer);
    },

    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/userGambar/" + value;
      } else {
        return (
          process.env.VUE_APP_API_BASE + "upload/userGambar/userDefault.png"
        );
      }
    },

    logOut() {
      Cookie.delete();
      this.$router.push("/login").catch(() => {});
    },
  },
};
</script>
